import Nazvy from "../Nazvy";
import Hodnota from "../Hodnota";
import Tooltip from "@mui/material/Tooltip";

const ConfigTabulka = ({
  tabulka,
  cisloHlavnejTab,
  classPreTabulka,
  stlacene,
  slovnik,
  selectedLanguage,
  nevybrateMoznosti,
  najdene,
  najdenePopis,
  poznamka,
  updateKtoraTab,
  celyRiadok,
  ktoryNazov,
  nacrtky,
  schemy,
  vsetkyUdaje,
  finalSchemy,
  konektor,
  updateSchemy,
  updateNacrtky,
  updateKtoryNazov,
  updateFinalSchemy,
  updateNajdenePoznamky,
  updateKonektor,
  updateCelyRiadok,
  updateStlacene,
  najdenePoznamky,
  zakazana_moznost,
  updateNajdenePopis,
  updateNajdene,
  zakaznicke,
  updateZakaznicke,
  idPreTabulku,
}) => {
  return tabulka.map((hodnotaArray, indexTabulky) => {
    const handleHover = (i) => {
      updateKtoraTab(i);
    };

    const najdiVynimky = (dalsiCounter) => {
      let najdeneVeci = {
        0: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      };
      let najdeneVeciPopis = {
        0: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      };

      zakazana_moznost.forEach((skupinaVynimiek, skupinaIndex) => {
        skupinaVynimiek.vynimky.forEach((jednaVynimka, indexVynimky) => {
          let count = 0;
          let letter = "";
          let indexVynim = 0;
          for (let j = 0; j < jednaVynimka.length; j++) {
            if (
              jednaVynimka[j].toUpperCase() !== dalsiCounter[j].toUpperCase() &&
              jednaVynimka[j].toUpperCase() !== "X"
            ) {
              letter = jednaVynimka[j];
              indexVynim = j;
              count++;
            }
            if (jednaVynimka[j].toUpperCase() === "/") {
              letter = jednaVynimka[j + 1];
              indexVynim = j;
              count++;
              break;
            }
            if (count > 1) {
              break;
            }
          }
          if (count === 1) {
            // najdeneVeci[indexVynim].push(letter);
            najdeneVeci[indexVynim].push(letter);
            najdeneVeciPopis[indexVynim].push(skupinaVynimiek.popis);
          }
        });
      });
      // set najdene vsetkym
      updateNajdene(najdeneVeci);
      updateNajdenePopis(najdeneVeciPopis);
    };

    const handleClick = (riadok, indexTabulky, kolkyNazov, indexRiadka) => {
      // indexTabulky prva ma 0 dalsie 2 etc
      const elem = riadok[riadok.length - 1];
      let schemyPoKliku = [...schemy];
      let nacrtkyPoKliku = [...nacrtky];

      if (!najdene[indexTabulky].includes(elem)) {
        // NAZOV
        if (
          JSON.stringify(riadok) === JSON.stringify(celyRiadok[indexTabulky])
        ) {
          // odtuknutie aj nazvoch
          const nextNazov = ktoryNazov.map((c, i) => {
            if (i === indexTabulky) {
              // ked chces odtuknut to co uz je stalcene pre schemy, nacrty, nazov
              let nacrtky_doteraz = [...nacrtky];
              nacrtky_doteraz[i] = "";
              updateNacrtky(nacrtky_doteraz);

              let schemy_doteraz = [...schemy];
              schemy_doteraz[i] = "";
              updateSchemy(schemy_doteraz);

              return "";
            } else {
              // The rest haven't changed
              return c;
            }
          });
          updateKtoryNazov(nextNazov);
        } else {
          // TUKNUTE NIECO CO ESTE NENI
          const nextNazov = ktoryNazov.map((c, i) => {
            if (i === indexTabulky && elem !== "X") {
              const spravnyIndex = i === 0 ? 1 : i;
              let indexJednehoNazva = 0;
              vsetkyUdaje["tabulka" + spravnyIndex][kolkyNazov].nazvy.forEach(
                (jedenNazov) => {
                  // POKIAL JE ARRAY (NESTED NAZOV)
                  if (Array.isArray(jedenNazov)) {
                    let poslednaArrayDlzka =
                      jedenNazov[jedenNazov.length - 1].length;
                    // check ci schema
                    if (jedenNazov[0][0].split(" ")[0] === "Schéma") {
                      schemyPoKliku[spravnyIndex] = riadok
                        .slice(
                          indexJednehoNazva,
                          indexJednehoNazva + poslednaArrayDlzka
                        )
                        .join(", ");
                    }
                    // check ci nacrt
                    else if (jedenNazov[0][0].split(" ")[0] === "Rozmerový") {
                      nacrtkyPoKliku[spravnyIndex] = riadok
                        .slice(
                          indexJednehoNazva,
                          indexJednehoNazva + poslednaArrayDlzka
                        )
                        .join(", ");
                    }
                    // index ktorym posuvam sa v riadku
                    indexJednehoNazva = indexJednehoNazva + poslednaArrayDlzka;
                  }
                  // POKIAL Normalny nazov
                  else {
                    // check ci schema
                    if (jedenNazov.split(" ")[0] === "Schéma") {
                      if (
                        !["nasl. tabuľka"].includes(riadok[indexJednehoNazva])
                      )
                        schemyPoKliku[spravnyIndex] = riadok[indexJednehoNazva];
                    }
                    // check ci nacrt
                    else if (jedenNazov.split(" ")[0] === "Rozmerový") {
                      nacrtkyPoKliku[spravnyIndex] = riadok[indexJednehoNazva];
                    }
                    // index ktorym posuvam sa v riadku
                    indexJednehoNazva = indexJednehoNazva + 1;
                  }
                }
              );

              return vsetkyUdaje["tabulka" + spravnyIndex][kolkyNazov].nazvy;
            }
            // ked das custom zakaznicke
            else if (i === indexTabulky && elem === "X") {
              const spravnyIndex = i === 0 ? 1 : i;
              return vsetkyUdaje["tabulka" + spravnyIndex][kolkyNazov].nazvy;
            } else {
              // The rest haven't changed
              return c;
            }
          });
          updateSchemy(schemyPoKliku);
          schemyAlgorithm(schemyPoKliku);
          updateNacrtky(nacrtkyPoKliku);
          nacrtyAlgorithm(nacrtkyPoKliku);
          updateKtoryNazov(nextNazov);
        }

        // POZNAMKY A CELY RIADOK
        if (
          JSON.stringify(riadok) === JSON.stringify(celyRiadok[indexTabulky])
        ) {
          const nextRiadky = celyRiadok.map((c, i) => {
            if (i === indexTabulky) {
              // ked chces odtuknut to co uz je stalcene
              return [];
            } else {
              // The rest haven't changed
              return c;
            }
          });

          const nextPoznamky = najdenePoznamky.map((c, i) => {
            if (i === indexTabulky) {
              // ked chces odtuknut to co uz je stalcene
              return [];
            } else {
              // The rest haven't changed
              return c;
            }
          });
          updateNajdenePoznamky(nextPoznamky);
          updateCelyRiadok(nextRiadky);
          updateKonektor(false);
        } else {
          const nextRiadky = celyRiadok.map((c, i) => {
            if (i === indexTabulky) {
              return riadok;
            } else {
              // The rest haven't changed
              return c;
            }
          });
          if (elem !== "X") {
            const nextPoznamky = najdenePoznamky.map((c, i) => {
              if (i === indexTabulky) {
                if (riadok.join("").includes(")")) {
                  return riadok
                    .join(" ")
                    .split(" ")
                    .filter(
                      (elemR) =>
                        elemR.includes(")") & !isNaN(elemR.slice(0, -1))
                    );
                }

                return c;
              } else {
                // The rest haven't changed
                return c;
              }
            });
            updateNajdenePoznamky(nextPoznamky);
          }

          updateCelyRiadok(nextRiadky);
        }
      }

      // STLACENE
      if (!najdene[indexTabulky].includes(elem)) {
        if (elem !== "X") {
          if (stlacene[indexTabulky] === elem) {
            const nextCounters = stlacene.map((c, i) => {
              if (i === indexTabulky) {
                // ked chces odtuknut to co uz je stalcene
                return "x";
              } else {
                // The rest haven't changed
                return c;
              }
            });

            updateStlacene(nextCounters);
            najdiVynimky(nextCounters);
            return 0;
          }
        }

        const nextCounters = stlacene.map((c, i) => {
          if (i === indexTabulky) {
            // stalecene a zaznamenam
            return elem;
          } else {
            // The rest haven't changed
            return c;
          }
        });
        updateStlacene(nextCounters);
        najdiVynimky(nextCounters);

        if (riadok[0].split(" ").includes("konektor")) {
          if (!konektor) {
            updateKonektor(true);
          }
        } else {
          updateKonektor(false);
        }
      }
    };

    const handleX = (e, index) => {
      const nextCounters = zakaznicke.map((c, i) => {
        if (i === index) {
          // Increment the clicked counter
          return e.target.value;
        } else {
          // The rest haven't changed
          return c;
        }
      });
      updateZakaznicke(nextCounters);
    };

    const schemyAlgorithm = (rawSchemy) => {
      // console.log(rawSchemy);
    };

    const nacrtyAlgorithm = (rawNacrty) => {
      // console.log(rawNacrty);
    };

    function countConsecutiveKrat(arr) {
      let count = 0;

      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === "*") {
          count++;
        } else {
          break; // exit the loop when a different element is encountered
        }
      }

      return count + 1;
    }

    function removeLastPeriod(str) {
      // Check if the last character is a period
      if (str.charAt(str.length - 1) === ".") {
        // Remove the last character using slice
        return str.slice(0, -1);
      } else {
        // If the last character is not a period, return the original string
        return str;
      }
    }

    return (
      <div
        hidden={
          nevybrateMoznosti &&
          stlacene[cisloHlavnejTab].toLocaleLowerCase() !== "x"
            ? true
            : false
        }
      >
        <div id={idPreTabulku + indexTabulky} className={classPreTabulka}>
          <Nazvy
            tabulkaIteracia={hodnotaArray}
            prekladac={slovnik}
            jazyk={selectedLanguage}
            farba={stlacene[cisloHlavnejTab]}
            nevybrateMoznosti={nevybrateMoznosti}
            poznamka={poznamka}
          />

          <div className="table-content">
            {" "}
            {hodnotaArray.hodnoty.map((hodnota, indexRiadka) => {
              let indexy = cisloHlavnejTab;
              let momentalny_kod = hodnota[hodnota.length - 1];
              let znenieVynimky = null;
              if (najdene[indexy].includes(momentalny_kod)) {
                znenieVynimky =
                  najdenePopis[indexy][najdene[indexy].indexOf(momentalny_kod)];
              }
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={
                      znenieVynimky
                        ? slovnik.hasOwnProperty(
                            removeLastPeriod(znenieVynimky)
                          )
                          ? selectedLanguage === "EN"
                            ? slovnik[removeLastPeriod(znenieVynimky)][0] + "."
                            : selectedLanguage === "RU"
                            ? slovnik[removeLastPeriod(znenieVynimky)][1] + "."
                            : znenieVynimky
                          : znenieVynimky
                        : ""
                    }
                    placement="right"
                    arrow
                  >
                    <div
                      key={indexy.toString() + indexRiadka}
                      onClick={() => {
                        handleClick(hodnota, indexy, indexTabulky, indexRiadka);
                      }}
                      onMouseOver={() => handleHover(indexy)}
                      className={
                        najdene[indexy].includes(momentalny_kod)
                          ? "nemozes table-row"
                          : momentalny_kod === stlacene[indexy]
                          ? "stisknute table-row"
                          : "nestisknute table-row"
                      }
                    >
                      {/* Logika pre spajanie pri KRAT */}
                      {hodnota.map((hodnotaRiadok, indexHodnoty) => {
                        if (hodnota.length - 1 !== indexHodnoty) {
                          // dalsia hodnota ma * vypocitat flex
                          if (
                            hodnota[indexHodnoty + 1] === "*" &&
                            hodnotaRiadok !== "*"
                          ) {
                            const velkostFlexu = countConsecutiveKrat(
                              hodnota.slice(indexHodnoty + 1)
                            ).toString();

                            return (
                              <Hodnota
                                hodnotaRiadok={hodnotaRiadok}
                                slovnik={slovnik}
                                selectedLanguage={selectedLanguage}
                                poznamka={poznamka}
                                velkostFlexu={velkostFlexu}
                              />
                            );
                          } else if (hodnotaRiadok !== "*") {
                            return (
                              <Hodnota
                                hodnotaRiadok={hodnotaRiadok}
                                slovnik={slovnik}
                                selectedLanguage={selectedLanguage}
                                poznamka={poznamka}
                              />
                            );
                          } else return null;
                        } else if (hodnotaRiadok !== "*") {
                          return (
                            <Hodnota
                              hodnotaRiadok={hodnotaRiadok}
                              slovnik={slovnik}
                              selectedLanguage={selectedLanguage}
                              poznamka={poznamka}
                            />
                          );
                        } else return null;
                      })}
                    </div>
                  </Tooltip>
                  {indexRiadka !== hodnotaArray.hodnoty.length - 1 &&
                    hodnotaArray.hodnoty[indexRiadka + 1][0] !== hodnota[0] &&
                    (cisloHlavnejTab === 2 || cisloHlavnejTab === 4) && (
                      <div
                        style={{
                          backgroundColor: "#73ADD3",
                          height: "1px",
                          border: "0.5px solid #73ADD3",
                          borderRadius: "25px",
                          width: "95%",
                          marginTop: "2px",
                          marginBottom: "12px",
                        }}
                      ></div>
                    )}
                </div>
              );
            })}
          </div>
        </div>
        {(nevybrateMoznosti || zakaznicke[cisloHlavnejTab].length > 0) &&
          indexTabulky === tabulka.length - 1 && (
            <>
              <div className="poznamka-x">
                {selectedLanguage === "EN"
                  ? "Additional notes on configurations, questions:"
                  : selectedLanguage === "RU"
                  ? "Дополнительные замечания по конфигурациям, вопросы:"
                  : "Doplňujúce poznámky ku konfigurácií, otázky:"}
              </div>
              <div
                className={
                  "X" === stlacene[cisloHlavnejTab]
                    ? "stisknute table-row-x"
                    : "nestisknute table-row-x"
                }
                onMouseOver={() => handleHover(cisloHlavnejTab)}
                onClick={() => {
                  handleClick("X", cisloHlavnejTab, 0);
                }}
              >
                <input
                  className="table-data-zakaznicke"
                  placeholder={
                    selectedLanguage === "EN"
                      ? "Enter the desired specification here ..."
                      : selectedLanguage === "RU"
                      ? "Введите здесь желаемую спецификацию ..."
                      : "Sem zadajte požadovanú špecifikáciu ..."
                  }
                  value={zakaznicke[cisloHlavnejTab]}
                  onChange={(e) => handleX(e, cisloHlavnejTab)}
                ></input>
                <div className="table-data-zakaznicke">X</div>
              </div>
            </>
          )}
      </div>
    );
  });
};

export default ConfigTabulka;
